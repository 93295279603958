.public-clip-card-list {
  width: 100%;
  max-width: 100%;
  @apply @container;

  &[data-list-display-format="list"] .clip-card-list-items {
    @apply space-y-4;
  }

  &[data-list-display-format="grid"]:not([data-variant]) .clip-card-list-items {
    display: grid;
    @apply grid-cols-1 @sm:grid-cols-2 @4xl:grid-cols-3 @6xl:grid-cols-4;

    @apply gap-4 @2xl:gap-8;
  }

  &[data-list-display-format="grid"][data-variant="highlights"] .clip-card-list-items {
    @apply grid md:grid-cols-2 lg:grid-cols-3 gap-8 xl:gap-10;
  }
}
